
// http://youmightnotneedjquery.com/

let Survey = {}

Survey.init = () => {
  const handlePreviousClick = (event) => {
    const form = document.querySelector('form#survey');
    const input = document.createElement("input")
    input.type = "hidden";
    input.name = "previous";
    input.value = "true";

    form.appendChild(input);
    form.submit();
  }

  const handleFormSubmit = (event) => {
    document.querySelectorAll('form#survey input[type="submit"]').forEach(button => {
      // We can't just set "button.disabled=true"; the value will not get submitted.
      // Instead, we'll discard future clicks and add a disabled class.
      button.addEventListener('click', (clickEvent) => {
        clickEvent.preventDefault();
      })
      button.classList.add('disabled')
    })
  }

  document.querySelector('input[value~="Previous"][type="button"]')
          .addEventListener('click', handlePreviousClick)

  // If the question itself has submit buttons, then get rid of the "next" button
  if (document.querySelector('.question input[type="submit"]')){
    document.querySelector('input[type="submit"][value="Next"]').classList.add('d-none')
  }

  document.querySelector('form#survey')
          .addEventListener('submit', handleFormSubmit);
}

export default Survey;