
// http://youmightnotneedjquery.com/

let Home = {}

Home.init = () => {
  const handleStateChange = (event) => {
    const newState = event.target.value;
    const activePatrolSelector = document.querySelector('#active-patrols')
    const potentialPatrolSelector = document.querySelector('#potential-patrols')

    
    // Enable PatrolSelector
    activePatrolSelector.removeAttribute('disabled')
    activePatrolSelector.classList.remove('d-none')

    // Move any existing patrols to potential
    activePatrolSelector.querySelectorAll('option:not([disabled])').forEach((item, i) => {
      potentialPatrolSelector.appendChild(item)
    })

    // Move potential patrols at newState to active patrols
    potentialPatrolSelector.querySelectorAll(`option[data-state=${newState}]`).forEach((item, i) => {
      activePatrolSelector.appendChild(item)
    })

    // Activate "Select a Patrol"
    document.querySelector('#active-patrols').selectedIndex = 0
  }

  const handlePatrolChange = (event) => {
    const form = event.target.form;
    form.submit();
  }

  let stateSelector = document.querySelector('#survey-state-select')
  let activePatrolSelector = document.querySelector('#active-patrols')

  stateSelector.addEventListener('change', handleStateChange)
  activePatrolSelector.addEventListener('change', handlePatrolChange)

  if (stateSelector.value !== ''){
    const event = document.createEvent('HTMLEvents');
    event.initEvent('change', true, false);
    stateSelector.dispatchEvent(event);
  }
}

export default Home;