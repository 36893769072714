import EmblaCarousel from 'embla-carousel'
// import Autoplay from 'embla-carousel-autoplay'
import AutoHeight from 'embla-carousel-auto-height'

// http://youmightnotneedjquery.com/

let Testimonials = {}

const setupDotBtns = (dotsArray, embla) => {
  dotsArray.forEach((dotNode, i) => {
    dotNode.addEventListener("click", () => embla.scrollTo(i), false);
  });
};

const generateDotBtns = (dots, embla) => {
  const template = document.getElementById("embla-dot-template").innerHTML;
  dots.innerHTML = embla.scrollSnapList().reduce(acc => acc + template, "");
  return [].slice.call(dots.querySelectorAll(".embla__dot"));
};

const selectDotBtn = (dotsArray, embla) => () => {
  const previous = embla.previousScrollSnap();
  const selected = embla.selectedScrollSnap();
  dotsArray[previous].classList.remove("is-selected");
  dotsArray[selected].classList.add("is-selected");
};

Testimonials.init = () => {
  const emblaNode = document.querySelector('.embla')
  const viewPort = emblaNode.querySelector('.embla__viewport')
  const options = { loop: true, skipSnaps: false }
  // const plugins = [Autoplay({ delay: 10000 })] // Plugins
  const plugins = [AutoHeight({destroyHeight: 'auto'})] // Plugins
  // const plugins = [] // Plugins
  const embla = EmblaCarousel(viewPort, options, plugins)

  const dots = document.querySelector(".embla__dots");
  const dotsArray = generateDotBtns(dots, embla);

  const setSelectedDotBtn = selectDotBtn(dotsArray, embla);
  setupDotBtns(dotsArray, embla);

  embla.on("select", setSelectedDotBtn);
  embla.on("init", setSelectedDotBtn);
}

export default Testimonials;